import { useRef, useState, useEffect } from 'react';
import { Button } from '@geberit/gdds';

// styles
import styles from './tooltip.module.scss';

// types
import type { Hotspot } from './types';

// utils
import { classNameBuilder } from 'utils/classNameBuilder';
import { decodingContent } from 'utils/decodingContent';

interface TooltipProps {
  tooltip: string; // text
  top: string;
  left: string;
  color: string;
  defaultPosition?: string;
  size?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  setCurrentTooltip?: (currentTooltip?: Hotspot) => void;
  onLeave?: () => void;
  visible?: boolean;
}

export default function Tooltip({
  tooltip,
  top,
  left,
  defaultPosition = 'right',
  size = 'small',
  color,
  onClick,
  visible = true,
  onLeave,
  setCurrentTooltip,
}: Readonly<TooltipProps>) {
  const tooltipRef = useRef<HTMLSpanElement | null>(null);
  const [offsetLeft, setOffsetLeft] = useState('');
  const offsetTop = defaultPosition === 'right' ? '- 15' : '+ 20';
  const [parentRef, setParentRef] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    const mouseMove = (e) => {
      if (!parentRef?.contains(e.target) && e.target.tagName !== 'circle') {
        setCurrentTooltip?.();
      }
    };

    if (parentRef && setCurrentTooltip) {
      window.addEventListener('mousemove', mouseMove);
    }

    return () => {
      window.removeEventListener('mousemove', mouseMove);
    };
  }, [setCurrentTooltip, parentRef]);

  useEffect(() => {
    if (defaultPosition === 'bottom' && tooltipRef?.current) {
      // tooltip-text-width + padding right and left / 2
      if (size === 'big') {
        setOffsetLeft(`calc(${left} - ${(tooltipRef.current.offsetWidth + 70) / 2}px)`);
      } else {
        setOffsetLeft(`calc(${left} - ${(tooltipRef.current.offsetWidth + 16) / 2}px)`);
      }
    } else if (tooltipRef?.current) {
      setOffsetLeft(`calc(${left} + 12px`);
    }
  }, [defaultPosition, left, size, tooltip]);

  if (!visible && !tooltip) return null;

  return (
    <>
      {onClick ? (
        <div ref={(ref) => setParentRef(ref)}>
          <Button
            stylingType="secondary"
            className={classNameBuilder(
              styles.tooltip,
              styles.button,
              styles[defaultPosition],
              styles[size],
              styles[color],
            )}
            style={{
              top: `calc(${top} ${offsetTop}px)`,
              left: offsetLeft,
            }}
            onClick={onClick}
            onMouseLeave={onLeave}
          >
            <span ref={tooltipRef}>{decodingContent(tooltip)}</span>
          </Button>
        </div>
      ) : (
        <div
          className={classNameBuilder(
            styles.tooltip,
            styles[defaultPosition],
            styles[size],
            styles[color],
          )}
          style={{
            top: `calc(${top} ${offsetTop}px)`,
            left: offsetLeft,
          }}
          ref={(ref) => setParentRef(ref)}
        >
          <span ref={tooltipRef}>{decodingContent(tooltip)}</span>
        </div>
      )}
    </>
  );
}
